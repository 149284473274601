<template>
  <div class="data-view-box">
    <van-nav-bar title="数据看板" left-text="返回" left-arrow @click-left="onClickLeft" v-if="!$root.isInMini" />
    <div class="data-con" v-if="userInfo">
      <div class="user">
        <img :src="userInfo.companyVO.avatarUrl" alt />
        <div>你好，{{ userInfo.userSelfhelpVO.userName }}，祝你开心每一天！</div>
      </div>
      <div class="data-overview-box">
        <p>数据概况</p>
        <van-grid :column-num="3">
          <van-grid-item icon="photo-o">
            <p>{{ homeDeliveryVO.deliveryTotal || 0 }}</p>
            <p>总投递量</p>
          </van-grid-item>
          <van-grid-item icon="photo-o">
            <p>{{ homeDeliveryVO.untreatedTotal || 0 }}</p>
            <p>待处理量</p>
          </van-grid-item>
          <van-grid-item icon="photo-o">
            <p>{{ homeDeliveryVO.interviewTotal || 0 }}</p>
            <p>面试通过量</p>
          </van-grid-item>
          <van-grid-item icon="photo-o">
            <p>{{ homePositionVO.positionTotal || 0 }}</p>
            <p>岗位总数</p>
          </van-grid-item>
          <van-grid-item icon="photo-o">
            <p>{{ homePositionVO.recruittingTotal || 0 }}</p>
            <p>在线岗位数</p>
          </van-grid-item>

          <van-grid-item icon="photo-o">
            <p>{{ homePositionVO.overdueTotal || 0 }}</p>
            <p>下架岗位数</p>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="bg"></div>
      <div class="data-chart-box">
        <p>投递曲线</p>
        <div class="btn-box">
          <div class="week" :class="{ active: type === 'week' }" @click="search('week')">
            最近7天
            <img v-if="type === 'week'" src="../../assets/release/checked.png" />
          </div>
          <div class="month" :class="{ active: type === 'month' }" @click="search('month')">
            最近30天
            <img v-if="type === 'month'" src="../../assets/release/checked.png" />
          </div>
          <div class="pick" :class="{ active: type === 'pick' }" @click="search('pick')">
            自定义
            <img v-if="type === 'pick'" src="../../assets/release/checked.png" />
          </div>
        </div>
        <div class="date-box" v-if="type === 'pick'">
          <div class="start">
            <img class="date" src="../../assets/common/date.png" alt />
            <div>{{ startTime | dateFilter }}</div>
          </div>
          <div class="end">
            <span>至</span>
            <div>{{ endTime | dateFilter }}</div>
          </div>
        </div>
        <div id="statistic-analysis"></div>
      </div>
    </div>
    <van-calendar
      v-if="year"
      v-model="dateShow"
      :min-date="new Date(year, 1, 1)"
      type="range"
      color="#206cfe"
      @confirm="onConfirm"
      @close="onCancel"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import moment from 'moment';
export default {
  name: 'dataView',
  data() {
    return {
      dateShow: false,
      year: new Date().getFullYear() - 20,
      startTime: '',
      endTime: '',
      type: '',
      echart: null,
      userInfo: {
        userSelfhelpVO: {
          userName: '',
        },
        companyVO: {
          avatarUrl: '',
        },
      },
      homeDeliveryVO: {
        deliveryTotal: 0,
        interviewTotal: 0,
        untreatedTotal: 0,
      },
      homePositionVO: {
        overdueTotal: 0,
        positionTotal: 0,
        recruittingTotal: 0,
      },
      option: {
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
          },
        ],
      },
    };
  },
  filters: {
    dateFilter: function (data) {
      let back = '';
      if (data) {
        let arr = data.split('-');
        back = arr[0] + '年' + arr[1] + '月' + arr[2] + '日';
      }
      return back;
    },
  },
  async mounted() {
    let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
    if (back.code === 200) {
      localStorage.setItem('userInfo', JSON.stringify(back.data));
      this.userInfo = back.data;
    }
    this.getAllData();
  },
  methods: {
    getAllData() {
      this.$http
        .get('/selfhelpOfficialAccounts/users/homeData')
        .then((res) => {
          if (res.code === 200) {
            let data = res.data.homeDeliveryByDays;
            let xData = [];
            let yData = [];
            data.forEach((item) => {
              xData.push(item.deliveryDate);
              yData.push(item.deliveryTotal);
            });
            this.option.xAxis.data = xData;
            this.option.series[0].data = yData;
            this.homeDeliveryVO = res.data.homeDeliveryVO;
            this.homePositionVO = res.data.homePositionVO;
            this.echartsInit();
          }
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    search(type) {
      this.type = type;
      if (type === 'week') {
        const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
        const endDate = moment().subtract(0, 'days').format('YYYY-MM-DD');
        this.startTime = startDate;
        this.endTime = endDate;
        this.getHomeData();
      } else if (type === 'month') {
        const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        const endDate = moment().subtract(0, 'days').format('YYYY-MM-DD');
        this.startTime = startDate;
        this.endTime = endDate;
        this.getHomeData();
      } else {
        this.dateShow = true;
      }
    },
    getHomeData() {
      this.$http
        .get('/selfhelpOfficialAccounts/users/homeData', {
          start: this.startTime,
          end: this.endTime,
        })
        .then((res) => {
          if (res.code === 200) {
            let data = res.data.homeDeliveryByDays;
            let xData = [];
            let yData = [];
            data.forEach((item) => {
              xData.push(item.deliveryDate);
              yData.push(item.deliveryTotal);
            });
            this.option.xAxis.data = xData;
            this.option.series[0].data = yData;
            this.echartsInit();
          }
        });
    },
    onConfirm(value) {
      if (value.length === 2) {
        this.startTime = moment(value[0]).format('YYYY-MM-DD');
        this.endTime = moment(value[1]).format('YYYY-MM-DD');
      } else {
        this.startTime = '';
        this.endTime = '';
      }
      this.dateShow = false;
      this.getHomeData();
    },
    onCancel() {
      this.startTime = this.startTime || '';
      this.endTime = this.endTime || '';
    },
    echartsInit() {
      // 基于准备好的dom，初始化echarts实例 写在 mounted（）里面
      let analysis = echarts.init(
        document.getElementById('statistic-analysis')
      );
      this.drawLine(analysis);
      window.onresize = function () {
        analysis.resize();
      };
    },
    //写在methods方法里面封装然后在，在mounted()里面调用个人比较喜欢这种的写法，方便封装
    drawLine(name) {
      // 绘制图表
      name.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-view-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .data-con {
    flex: 1;
    overflow-y: auto;
    .user {
      height: 50px;
      line-height: 50px;
      margin: 16px 0 32px 0;
      padding: 0 15px;
      display: flex;
      img {
        height: 50px;
        width: 50px;
        margin-right: 16px;
        border-radius: 50%;
      }
      div {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .data-overview-box {
      padding: 0 15px;
      > p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-bottom: 10px;
      }
      .van-grid-item {
        p:first-child {
          font-size: 24px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #206cfe;
        }
        p:last-child {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .bg {
      height: 10px;
      background: #f7f7f7;
    }
    .data-chart-box {
      padding: 0 15px;
      > p {
        margin-top: 30px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-bottom: 10px;
      }
      .btn-box {
        width: 100%;
        display: flex;
        div {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          background: #f7f7f7;
          border-radius: 2px;
          padding: 5px 12px;
          margin-right: 16px;
        }
        .active {
          background: #e8f0fe;
          color: #206cfe;
          position: relative;
          img {
            position: absolute;
            height: 12px;
            width: 12px;
            top: 0;
            right: 0;
          }
        }
      }
      .date-box {
        margin: 20px 0px 11px 0;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 9px;
        .start {
          img {
            height: 15px;
            width: 15px;
          }
        }
        > div {
          display: flex;
          flex: 1;
          align-items: center;
          padding-left: 5px;
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          > div {
            flex: 1;
            text-align: center;
            margin-left: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      #statistic-analysis {
        height: 300px;
        width: 100%;
      }
    }
  }
}
</style>
